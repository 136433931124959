@import "~include-media/dist/include-media";
@import '../../../styles/common';

.join-our-team-cta {
  display: inline-block;
  font-size: rem(legacy-rem(16));
  height: rem(legacy-rem(64));
  width: rem(legacy-rem(450));
  max-width: rem(legacy-rem(59));
  position: relative;

  @include media(">=md") {
    display: none;
  }


  &__title {
    color: rgb(102,102,102);
    display: flex;
    flex-direction: column;
    font-family: $toolTipFont;
    width: rem(legacy-rem(130));
    position: relative;
    right: rem(legacy-rem(170));
    top: rem(legacy-rem(80));

    span {
      font-size: rem(legacy-rem(18));
      transform: rotate(6.073rad);
      position: relative;
      left: rem(legacy-rem(90));
      top: rem(legacy-rem(-30));
    }
  }

  &__image {
    align-self: center;
    fill: $brand-pink;
    margin: auto;
    transform: rotate(97deg) rotateX(180deg) translate(-#{rem(legacy-rem(14.5))}, #{rem(legacy-rem(17.5))});
    width: rem(legacy-rem(40));
  }

  &__link {
    color: $primary;
    display: inline-block;
    line-height: rem(legacy-rem(22));
    text-decoration: none;
    transition: color 0.15s ease-in-out;

    @include media(">=md") {
      line-height: rem(legacy-rem(35));
    }
  }

  &__underline {
    transform: translateY(-#{rem(legacy-rem(10))});

    path {
      fill: $brand-pink;
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
    }
  }
}

@keyframes hoverColor {
  100% {
    fill: $brand-blue;
  }
}

