@import "../../../styles/colors";

.buttonContainer {
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  background: $secondary-red;
  transform: rotate(45deg);
  border-radius: 50%;
  svg {
    display: inline-block;
    position:absolute;
    top: 32%;
    left: 32%;
    width: 20px !important;
    height: 20px !important;
    opacity: 1 !important;
  }
}
