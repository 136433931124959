@import "~include-media/dist/include-media";
@import '../../styles/common';

.HomeVideoLightBoxContainer {
  .btnTextWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 180px;
  }

  .textWrapper {
    margin-left: 16px;
    letter-spacing: 0;
    text-align: left;
    font-family: $bodyFont;
    font-size: rem(legacy-rem(20));
    line-height: rem(legacy-rem(35));
    color: #4A4A4A;
    font-weight: $medium;
    max-width: 220px;
    word-wrap: break-word;

    @include media(">=xl") {
      max-width: 277px;
    }
  }

  .lightboxTextWrapper {
    display: none;

    @include media('>=lg') {
      display: block;
    }
  }
}
